<template>
    <div id="piaoju1" style="display: none;">
      <div class="hiprint-printTemplate" v-for="(pageItem,index1) in pageData" :key="index1">
        <div class="hiprint-printPanel panel-index-0" :id='"panelChang"+index1'>
          <div class="hiprint-printPaper" original-height="297"
            style="width: 210mm; height: 297mm;background-color: bisque;">
            <div class="hiprint-printPaper-content">
              <!-- <div tabindex="1" class="hiprint-printElement hiprint-printElement-text"
                style="font-size: 18pt;position: absolute; width: 295pt; height: 21pt; font-size: 18pt; font-weight: 600; letter-spacing: 2.5pt; color: rgb(41, 53, 227); text-align: center; line-height: 18pt; left: 150pt; top: 15pt;">
                <div class="hiprint-printElement-text-content hiprint-printElement-content"
                  style="height:100%;width:100%">{{info.name}}{{index1}}</div>
              </div> -->
              <div class="hiprint-printElement hiprint-printElement-text"
                style="position: absolute; width: 80pt; height: 12pt; color: rgb(41, 53, 227); left: 12pt; top: 15pt;">
                <table border="1" cellpadding="0" cellspacing="0"
                  style="text-align: center;width: 560pt; height: 20pt; color: rgb(41, 53, 227); font-size: 12pt;">
                  <tr style="text-align: center;width: 560pt; height: 20pt; color: rgb(41, 53, 227); font-size: 12pt;">
                    <td width="40">姓名</td>
                    <td width="30">班次</td>
                    
                    <td width="60">透析模式</td>
                    <td width="80">血透器</td>
                    <td width="100">抗凝剂</td>
                    <td width="60">干体重</td>
                    <td width="60">上次透后体重</td>
                  </tr>
                  <tr v-for="(item,index) in pageItem" :key="index"
                    style="text-align: center;width: 560pt; height: 20pt; line-height:15pt;color: rgb(41, 53, 227); font-size: 12pt;">
                    <td>{{item.patient.name}}</td>
                    <td>{{item.nm_name}}</td>
                    
                    <td>{{item.nm_dialyse_way}}</td>
                    <td>{{item.dict_touxiqi_name}}</td>
                    <td>{{item.dict_kangning_name}}</td>
                    <td>{{item.dry_weight}}</td>
                    <td>{{item.after_weight}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script setup>
  import { ref, onMounted ,defineExpose} from 'vue';
   //A4的panel设置
   const a4Panel = { width: 210, height: 297, paperFooter: 290, paperHeader: 2, paperNumberDisabled: true,'orient': 1}
  import { autoConnect, disAutoConnect, hiprint, defaultElementTypeProvider } from 'vue-plugin-hiprint'
  const info = ref('')
  const pageData = ref([])
  const tableData = ref([])
  const page = 1
  const pageSize = 99
  const hiprintTemplate = ref({})
  onMounted(() => {
    hiprint.init()//初始化
    let hiprintTemplate_ = new hiprint.PrintTemplate();
    hiprintTemplate.value = hiprintTemplate_
  })
  const initData = (item) => {
    tableData.value = []
    info.value = {}
    pageData.value = []
    info.value = item
    tableData.value = JSON.parse(JSON.stringify(info.value.drug_list))
    for (let i = 0; i < Math.ceil(tableData.value.length / pageSize); i++) {
      pageData.value[i] = tableData.value.slice(i * pageSize, (i + 1) * pageSize)
    }
  }
  // disAutoConnect()// 取消自动连接直接打印客户端
  const print = () => {
    let hiprintTemplate_ = new hiprint.PrintTemplate();
    pageData.value.forEach((item, index) => {
      console.log(2222,item)
      let panel = hiprintTemplate_.addPrintPanel(a4Panel);
      panel.addPrintHtml({ options: { width: 210, height: 297,  top: 2, left: 10, content: $('#panelChang' + index).html() } });
    })
    hiprintTemplate_.print();
  }
  defineExpose({
    info,
    print,
    initData,
    hiprintTemplate
  })
</script>
<style scoped>
  .patentList {
    background-color: #fff;
  }
</style>