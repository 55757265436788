<template>
  <div class="adviceBox">
    <!-- 顶部搜索框 -->
    <div class="usertop">
      <!-- 左侧搜索 -->
      <div class="display_flex">
        <el-form :inline="true" :model="formInline" class="demo-form-inline w-144 display_flex">
          <el-form-item>
            <el-date-picker v-model="formInline.time" type="date" @change="tobody"></el-date-picker>
          </el-form-item>
          <el-button class="color-grey" @click="print()" style="margin-top: -15px;">
            <i class="fa fa-print"></i>
            打印
          </el-button>
        </el-form>

      </div>
    </div>
    <!-- tabs标签页 -->
    <div>
      <el-table v-loading="loading" ref="multipleTableRef" :data="tableData" style="width: 100%" height="550" border>
        <el-table-column property="patient.name" label="姓名" />
        <el-table-column property="nm_name" label="班次" />
        <el-table-column width="170" property="nm_dialyse_way" label="透析模式" />
        <el-table-column property="dict_touxiqi_name" label="血透器" />
        <el-table-column property="dict_kangning_name" label="抗凝剂" />
        <el-table-column width="80" property="dry_weight" label="干体重" />
        <el-table-column property="after_weight" label="上次透后体重" />
      </el-table>
    </div>
    <printVue ref="printRef"></printVue>
  </div>
</template>

<script>
  import printVue from './printVue'
  import { toRefs, reactive, onMounted, ref, getCurrentInstance,nextTick } from 'vue'
  import { getDay } from '@/utils/tool.js'
  import service from '@/utils/request'
  // import dialysismachine from './dialysismachine.vue'
  export default {
    components: {
      printVue
    },
    setup() {
      const { proxy } = getCurrentInstance()
      const state = reactive({
        activeName: '1',
        formInline: {
          region: '上午',
          time: getDay(1),
        },
        tableData: [],
      })
      onMounted(() => {
        tobody()
      })
      // 获取列表
      const tobody = async () => {
        state.loading = true
        let data = {
          date: state.formInline.time,
        }
        let res = await service.post('/api/schedule_beds/prepare_list', data)
        if (res.code === 0) {
          if (res.data.schedule_list.length > 0) {
            state.tableData = res.data.schedule_list
          } else {
            state.tableData = []
          }
        }
        state.loading = false
      }
      const print = async () => {
        // let data = {
        //       name: '透前准备单',
        //       drug_list: state.tableData
        //     }
        //     proxy.$refs.printRef.initData(data)
        //     nextTick(() => {
        //       proxy.$refs.printRef.print()
        //     })
        let data = {
          date: state.formInline.time,
        }
        let res = await service.post('/api/schedule_beds/prepare_list', data)
        state.dataListLoading = false
        if (res.code === 0) {
          if (res.data.schedule_list.length > 0) {
            let data = {
              name: '透前准备单',
              drug_list: res.data.schedule_list
            }
            proxy.$refs.printRef.initData(data)
            nextTick(() => {
              proxy.$refs.printRef.print()
            })
          } else {
            ElMessage.error('暂无打印信息！')
          }
        }

      }
      return {
        ...toRefs(state),
        tobody,
        print
      }
    },
  }
</script>

<style scoped lang="scss">
  .adviceBox {
    height: 728px;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 10px;
  }
</style>